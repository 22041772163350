<template>
  <div>
    <b-row>
      <!-- pazienti -->
      <b-col cols="12" lg="4">
        <b-card class="mb-4">
          <b-row>
            <b-col cols="12" lg="4"><i class="mdi mdi-human-male-female fa-4x"></i></b-col>
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="12" lg="12"><h3>Pazienti</h3></b-col>
                <b-col cols="12" lg="12"><h4>{{items.total_patient}}</h4></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- operatori -->
      <b-col cols="12" lg="4">
        <b-card class="mb-4">
          <b-row>
            <b-col cols="12" lg="4"><i class="mdi mdi-account-multiple fa-4x"></i></b-col>
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="12" lg="12"><h3>Operatori</h3></b-col>
                <b-col cols="12" lg="12"><h4>{{items.total_operator}}</h4></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- prenotazioni -->
      <b-col cols="12" lg="4">
        <b-card class="mb-4">
          <b-row>
            <b-col cols="12" lg="4"><i class="mdi mdi-calendar fa-4x"></i></b-col>
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="12" lg="12"><h3>Prenotazioni</h3></b-col>
                <b-col cols="12" lg="12"><h4>{{items.total_reservation}}</h4></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="12">
        <b-card class="mb-4">
          <b-card-title class="text-center">
            Calendario Prenotazioni
          </b-card-title>
          <Calendar />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    Calendar: () => import('@/views/apps/calendar/Calendar')
  },
  data() {
    return {
      items: {}
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      const res = await this.axios.get('dashboard/home')
      this.items = res.data
    }
  }
}
</script>

<style scoped>

</style>